import { BeneficialFormValues, CitizenshipType } from '@dltru/dfa-models'
import {
    AddressFormSimple,
    BuisnessControl,
    Button,
    CardDivider,
    Divider,
    EmailInput,
    FioForm,
    Form,
    PersonalForm,
    PhoneInput,
    Space,
    WrappedInput,
} from '@dltru/dfa-ui'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, Fragment, useContext, useState } from 'react'

import { InnInput } from '@components/Form'
import { DocumentForm } from '@components/Form/components/DocumentForm'
import { UploadInputForm } from '@components/Form/components/UploadInputForm'

import { MigrationCardAndPermitBlock } from '../components/MigrationCardAndPermitBlock'

interface IComponentProps {
    uneditable: boolean
    listName: string
}
export const BeneficiariesBlock: FC<IComponentProps> = ({ uneditable, listName }) => {
    const { getFieldsValue } = useContext(FieldContext)
    const values = getFieldsValue()
    const [migrationBlockView, setMigrationBlockView] = useState(
        values?.[listName]?.reduce((acc, item: BeneficialFormValues, index: number) => {
            acc[index] = Boolean(item.citizenship) && item.citizenship !== CitizenshipType.RU
            return acc
        }, {}) ?? {},
    )
    const onChangeCitizenship = (name: number) => (value: CitizenshipType) => {
        setMigrationBlockView((prevValue) => ({
            ...prevValue,
            [name]: Boolean(value) && value !== CitizenshipType.RU,
        }))
    }

    return (
        <Form.List name={listName}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }) => {
                        const props = { ...restField, listName, uneditable, fieldKey: name }
                        return (
                            <Fragment key={key}>
                                <Divider orientation="left" margin={[32, 0]}>
                                    <Space>
                                        Бенефициар
                                        {fields.length > 1 && !uneditable && (
                                            <Button
                                                type="link"
                                                size="small"
                                                onClick={() => remove(name)}
                                            >
                                                Удалить
                                            </Button>
                                        )}
                                    </Space>
                                </Divider>
                                <BuisnessControl
                                    uneditable={props.uneditable}
                                    fieldName={name}
                                    owns_quarter={
                                        values?.[listName]
                                            ? values?.[listName][name]?.owns_quarter
                                            : false
                                    }
                                    legal_entity_control={
                                        values?.[listName]
                                            ? values?.[listName][name]?.legal_entity_control
                                            : false
                                    }
                                />
                                <Divider />
                                <WrappedInput {...props} name={[name, 'uuid']} hidden />
                                <div className="formRow">
                                    <WrappedInput
                                        {...props}
                                        className="formComponentItem"
                                        label="Статус"
                                        name={[name, 'status']}
                                        placeholder="Например, акционер"
                                        required
                                        maxLength={50}
                                    />
                                </div>
                                <div className="formRow">
                                    <WrappedInput
                                        {...props}
                                        className="formComponentItem"
                                        label="Подтверждающий документ"
                                        name={[name, 'confirmation_document']}
                                        placeholder=""
                                        required
                                        maxLength={100}
                                    />
                                </div>
                                <div className="formRow" style={{ marginLeft: 240 }}>
                                    <UploadInputForm
                                        {...props}
                                        name={[name, 'confirmation_document_file']}
                                        required
                                        title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                                        maxSizeMb={5}
                                        accept=".pdf,.png,.jpeg, .jpg, .tiff"
                                    />
                                </div>
                                <CardDivider />
                                <FioForm {...props} />
                                <PersonalForm
                                    {...props}
                                    onChangeCitizenship={onChangeCitizenship(name)}
                                />
                                <AddressFormSimple
                                    {...props}
                                    addressTitle="Место жительства (адрес регистрации по паспорту)"
                                />
                                <DocumentForm {...props} />
                                <div className="formRow">
                                    <InnInput
                                        {...props}
                                        className="formComponentItem"
                                        name={[name, 'inn']}
                                        required
                                    />
                                </div>
                                <div className="formRow">
                                    <PhoneInput
                                        {...props}
                                        name={[name, 'phone_number']}
                                        className="formComponentItem"
                                        label="Телефон"
                                    />
                                </div>
                                <div className="formRow">
                                    <EmailInput
                                        {...props}
                                        className="formComponentItem"
                                        name={[name, 'email']}
                                        label="Электронная почта"
                                    />
                                </div>
                                {migrationBlockView[name] && (
                                    <MigrationCardAndPermitBlock
                                        {...props}
                                        initialHasMigrationCard={
                                            values?.[listName]?.[name]?.HasMigrationCard as boolean
                                        }
                                        initialHasMigrationPermit={
                                            values?.[listName]?.[name]
                                                ?.HasMigrationPermit as boolean
                                        }
                                    />
                                )}
                            </Fragment>
                        )
                    })}
                    {!uneditable && (
                        <>
                            <CardDivider />
                            <Button type="link" onClick={() => add()}>
                                Добавить Бенефициара
                            </Button>
                        </>
                    )}
                </>
            )}
        </Form.List>
    )
}
