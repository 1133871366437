import { IApprovalHistoryItem, ProfileStatus } from '@dltru/dfa-models'
import { Alert, Box, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'

import styles from './styles.m.less'

interface IComponentProps {
    approvalHistory?: IApprovalHistoryItem[]
}
export const AlertForAnketa: FC<IComponentProps> = ({ approvalHistory }) => {
    if (!approvalHistory) {
        return null
    }
    const lastItem = approvalHistory.slice(-1).pop()
    if (
        lastItem?.profile_status !== ProfileStatus.REWORK &&
        lastItem?.profile_status !== ProfileStatus.REJECTED
    ) {
        return null
    }

    return (
        <Box margin={[0, 0, 8, 0]}>
            <Alert
                showIcon
                type={lastItem.profile_status === ProfileStatus.REWORK ? 'warning' : 'error'}
                description={
                    <div>
                        <span className={styles.gray}>
                            {dayjs.unix(lastItem.approval_datetime).format(VIEW_DATE_FORMAT)}
                        </span>
                        <div>{lastItem.comment}</div>
                    </div>
                }
                message={
                    lastItem.profile_status === ProfileStatus.REWORK
                        ? 'Анкета направлена на доработку'
                        : 'Анкета отклонена'
                }
            />
        </Box>
    )
}
