import {
    FinanceAndPlannedForm,
    Form,
    IRoleFormStepProps,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'
import { FinancialOperationsStepProfileType } from '@dltru/dfa-models/src/mappers/roleForms/common'

interface IComponentProps extends IRoleFormStepProps {
    stepIndex: number,
    profileType: FinancialOperationsStepProfileType
}

export const FinancialOperationsStep: FC<IComponentProps> = ({ stepIndex, isEditAllow, profileType }) => {
    const [form] = Form.useForm()

    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle="Сведения о финансировании и планируемых операциях"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FinanceAndPlannedForm
                uneditable={isEdit}
                financialActivityInitialValues={{
                    other: values?.other || '',
                    financial_activity_purpose: values?.financial_activity_purpose || [],
                }}
                profileType={profileType}
            />
            <div className="formRow" style={{ marginLeft: 240 }}>
                <UploadInputForm
                    name="sources_funds_file_uuids"
                    uneditable={isEdit}
                    title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
        </Step>
    )
}
